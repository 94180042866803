import "./../scss/main.scss";
import "./../scss/eligibleForm.scss";

import "bootstrap";

window.onload = (event) => {
  if (getUrlParameter("submit") === "true") {
    // show alert
    setTimeout(function () {
      document.querySelector("#submitSuccessAlert").classList.add("show");
    }, 600);
  }
};

function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
